import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
    en: {
        bottom: {
            callUs: 'Call Us',
            emailUs: 'Email Us',
            instagram: 'Instagram',
            ourAddress: 'Our Address',
            visitOurInstagramAccount: 'Visit Our Instagram Account',
        },
        customerExperience: {
            allRequiredFieldsMustBeFilledOut: 'All required (*) fields must be filled out',
            customerExperience: 'Customer Experience',
            details: 'Details',
            email: 'Email',
            enterYourName: 'Enter your name',
            howWasYourExperience: 'How was your experience with DC Painting?',
            name: 'Name',
            newest: 'Newest',
            optional: 'Optional',
            share: 'Share',
            sharedYourExperience: 'Share your experience!',
            sharedExperiences: 'Shared Experiences',
            sortBy: 'Sort By',
            whatDidYouLikeTheMost: 'What did you like the most?',
            title: 'Title',
        },
        promise: {
            cleanWorkAreas: 'Clean\nWork Areas',
            freeEstimates: '- Free Estimates, call now (407) 493 - 3648 -',
            onBudget: 'On Budget',
            onTime: 'On Time',
            professionalExpertise: 'Professional\nExpertise',
            subTitle: 'We provide high quality painting services and top-rated customer experience.',
            superiorQuality: 'Superior\nQuality',
            title: 'Our Promise to Deliver',
        },
        services: {
            commercialServices: 'Commercial\nServices',
            industrialServices: 'Industrial\nServices',
            residentialServices: 'Residential\nServices',
            title: 'Our Services',
        },
    },
    es: {
        bottom: {
            callUs: 'Llámanos',
            emailUs: 'Envíanos un correo electrónico',
            instagram: 'Instagram',
            ourAddress: 'Nuestra dirección',
            visitOurInstagramAccount: 'Visite nuestra cuenta de Instagram',
        },
        customerExperience: {
            allRequiredFieldsMustBeFilledOut: 'Todos los campos requeridos (*) deben llenarse',
            customerExperience: 'Experiencia del Cliente',
            details: 'Detalles',
            email: 'Correo Electrónico',
            enterYourName: 'Introduzca su nombre',
            howWasYourExperience: '¿Cómo fue tu experiencia con DC Painting?',
            name: 'Nombre',
            newest: 'Más Reciente',
            optional: 'Opcional',
            share: 'Comparte',
            sharedYourExperience: 'Comparte tu experiencia!',
            sharedExperiences: 'Experiencias compartidas',
            sortBy: 'Ordenar Por',
            whatDidYouLikeTheMost: '¿Qué es lo que más te gusto?',
            title: 'Título',
        },
        promise: {
            cleanWorkAreas: 'Áreas de\nTrabajo Limpias',
            freeEstimates: '- Presupuestos gratuitos, llame ahora (407) 493 - 3648 -',
            onBudget: 'Dentro del\nPresupuesto',
            onTime: 'A Tiempo',
            professionalExpertise: 'Experiencia\nProfesional',
            subTitle: 'Brindamos servicios de pintura de alta calidad y la mejor experiencia del cliente.',
            superiorQuality: 'Calidad\nSuperior',
            title: 'Nuestra Promesa',
        },
        services: {
            commercialServices: 'Servicios\nComerciales',
            industrialServices: 'Servicios\nIndustriales',
            residentialServices: 'Servicios\nResidenciales',
            title: 'Nuestros servicios',
        },
    },
});