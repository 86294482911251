import Colors from '../../Platform/Colors'

const styles = {
    bodySubTitle: {
        fontSize: 20,
        fontWeight: 'semi',
        textAlign: 'center',
    },
    circleIcon: {
        color: Colors.white,
        height: 50,
        width: 50,
    },
    container: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
    },
    sectionContainer: {
        alignItems: 'center',
        display: 'flex',
        gap: 25,
        justifyContent: 'space-around',
        marginTop: 35,
        maxWidth: 1800,
        width: '100%',
    },
    sectionsFooter: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        marginTop: 50,
        textAlign: 'center',
    },
}

export default styles
