const styles = {
    container: {
        borderRadius: 8,
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        margin: 10,
        padding: 10,
    },
};

export default styles;
