import React from 'react';
import './Header.css';
import styles from './Header.styles';
import ICON from '../../Assets/Company/icon2.png';
import LOGO from '../../Assets/Company/logo2.png';

const Header = () => (
    <div className='component_header_container' style={ styles.container }>
        <img alt='DC Painting Logo' className='component_header_logo' src={ LOGO } />
        <div className='header_right_container' style={ styles.headerRightContainer }>
            <img alt='DC Painting Icon' className='component_header_icon' src={ ICON } />
        </div>
    </div>
);

export default Header;
