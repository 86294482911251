import React, { useEffect, useState } from 'react'
import { FaChevronLeft, FaChevronRight, FaCircle } from 'react-icons/fa'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { handleResize, nextSlide } from './helpers'
import styles from './ImageSlider.styles'
import Colors from '../../../../Platform/Colors'

const ImageSlider = ({
  images = [],              // ARRAY - Contains images.
}) => {
  const [containerOrientation, setContainerOrientation] = useState('row')
  const [index, setIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(/* istanbul ignore next */ () => {
    handleResize(containerOrientation, setContainerOrientation)
    window.addEventListener('resize', () => handleResize(containerOrientation, setContainerOrientation))

    return window.removeEventListener('resize', () => handleResize(containerOrientation, setContainerOrientation))
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  useEffect(/* istanbul ignore next */ () => {
    let slideInterval = null
    if (!isLoading) slideInterval = setInterval(() => nextSlide(images, index, setIndex), 3000)

    return () => clearInterval(slideInterval)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [index, isLoading])

  return (
    <div className='container' style={ { ...styles.container, ...{ flexDirection: containerOrientation } } }>
      <div className='image_slider_container' style={ styles.image_slider_container }>
        { !!isLoading && <Skeleton height={ 450 } width={ '100%' } /> }
        <img
          alt={`img ${ index }`}
          className='ImageSlider_img'
          onLoad={ /* istanbul ignore next */ () => setIsLoading(false) }
          src={ images[index] }
          style={ { ...styles.img, ...{ display: !!isLoading ? 'none' : 'initial' } } }
        />
        <div style={ styles.buttons }>
          {
            images?.map((img, imgIndex) => (
              <button
                key={ `${ imgIndex }_shortcut` }
                onClick={ /* istanbul ignore next */ () => setIndex(imgIndex) }
                style={ styles.button }
              >
                <FaCircle style={ { ...styles.buttonIcon, ...{ color: index === imgIndex ? Colors.california : Colors.white } } } />
              </button>
            ))
          }
        </div>
        <button
          disabled={ index === 0 }
          onClick={ /* istanbul ignore next */ () => setIndex(index - 1) }
          style={ { ...styles.arrowContainer, ...{ left: '2%' } } }
        >
          <FaChevronLeft style={ { ...styles.arrow, ...{ color: index !== 0 ? Colors.white : 'transparent' } } } />
        </button>
        <button
          disabled={ index === (images?.length - 1) }
          onClick={ /* istanbul ignore next */ () => setIndex(index + 1) }
          style={ { ...styles.arrowContainer, ...{ right: '2%' } } }
        >
          <FaChevronRight style={ { ...styles.arrow, ...{ color: index !== (images?.length - 1) ? Colors.white : 'transparent' } } } />
        </button>
      </div>
    </div>
  )
}

export default ImageSlider
