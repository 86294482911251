export const handleResize = (containerOrientation, setContainerOrientation) => {
  if (window?.innerWidth > 730 ) {
    if (containerOrientation !== 'row') {
      setContainerOrientation('row')
    }
  } else {
    if (containerOrientation !== 'column') {
      setContainerOrientation('column')
    }
  } 
}

export const nextSlide = (images, index, setIndex) => {
  const nextIndex = index === (images?.length - 1) ? 0 : index + 1
  setIndex(nextIndex)
}
