import { initializeApp } from 'firebase/app'

const config = {
    apiKey: process.env.AIzaSyA38kK6gdinBKxd3leh5aWsF7ZLDLHVSvM,
    authDomain: "dc-painting-corp-web-app.firebaseapp.com",
    projectId: "dc-painting-corp-web-app",
    storageBucket: "dc-painting-corp-web-app.appspot.com",
    messagingSenderId: "148025019149",
    appId: "1:148025019149:web:d08fd2cc433c97c1f17e44",
    measurementId: "G-7HNPG3PWQS"
};

export default initializeApp(config);
