import React from 'react';
import { animated, useSpring } from 'react-spring';
import styles from './Toast.styles';
import Text from '../Text/Text';

const Toast = ({
    description = '',   // STRING - Description text.
    type = 'success',   // BOOL - Type of toast.
    visible = false,    // BOOL - Defines if toast is visible.
}) => {
    const props = useSpring({
        from: {
            opacity: 0,
            width: visible ? 0 : 250,
        },
        to: {
            opacity: 1,
            width: visible ? 250 : 0,
        },
    });

  return visible && (
    <animated.div style={ { ...props, ...styles.container, ...{ backgroundColor: type === 'success' ? 'green' : 'red' } } }>
        <Text style={ styles.title } text={ type === 'success' ? 'Success!' : 'Something Went Wrong!' } />
        <Text style={ styles.description } text={ description } />
    </animated.div>
    );
;}

export default Toast;
