import React from 'react'
import styles from './LayeredCircle.styles'

const LayeredCircle = ({
    color1,   // STRING - Outer color.
    color2,   // STRING - Middle color.
    color3,   // STRING - Inner color.
    icon,     // NODE - Icon to be displayed.
    text,     // STRING - Text to be displayed.
}) => (
    <section className='LayeredCircle' style={ styles.container }>
        <div className='circle1' style={ { ...styles.circle1, ...{ background: color1 } } }>
          <div className='cicle2' style={ { ...styles.circle2, ...{ background: color2 } } }>
            <div className='cicle3' style={ { ...styles.circle3, ...{ background: color3 } } }>
              { icon }
            </div>
          </div>
        </div>
        <p className='sectionText' style={ styles.circleText }>{ text }</p>
      </section>
)

export default LayeredCircle
