import React from 'react';
import styles from './Card.styles';

const Card = ({
    children,       // NODE - Children to enclosed.
    style,          // OBJECT - Custom style.
}) => (
    <div
        className='component_card'
        style={ {
            ...styles.container,
            ...style,
        } }
    >
        { children }
    </div>
);

export default Card;
