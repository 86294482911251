import React, { useEffect, useState } from 'react'
import { AiFillInstagram } from 'react-icons/ai'
import { FaPhoneAlt } from 'react-icons/fa'
import { HiLocationMarker } from 'react-icons/hi'
import { MdEmail } from 'react-icons/md'
import './App.css'
import styles from './App.styles.js'
import { getReviewsFromDB, onSubmitReview, renderFooter, sortReviews } from './helpers'
import { renderTitle } from './helpers.renders'
import Button from './Components/Button/Button'
import Card from './Components/Card/Card'
import Header from './Components/Header/Header'
import Input from './Components/Input/Input'
import Text from './Components/Text/Text'
import Toast from './Components/Toast/Toast'
import Intro from './Modules/Intro/Intro'
import Services from './Modules/Services/Services'
import Colors from './Platform/Colors'
import { trackVisit } from './Services/Firebase/Tracking/Tracking'
import { strings } from './Services/Localization/Localization.service'
import { isDevBuild } from './utility/OS/OS' 

function App() {
  const [formEmail, setFormEmail] = useState('')
  const [isFormDetailsEmpty, setIsFormDetailsEmpty] = useState(false)
  const [isFormHighlightEmpty, setIsFormHighlightEmpty] = useState(false)
  const [isFormIncomplete, setIsFormIncomplete] = useState(false)
  const [isFormNameEmpty, setIsFormNameEmpty] = useState(false)
  const [reviews, setReviews] = useState([])
  const [reviewsHighlight, setReviewsHighlight] = useState('')
  const [reviewsName, setReviewsName] = useState('')
  const [reviewsDetails, setReviewsDetails] = useState('')
  const [reviewsSortBy, setReviewsSortBy] = useState('')
  const [showFooterText, setShowFooterText] = useState(true)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastType, setToastType] = useState('')
  const footerSections = [
    {
      description: 'DC Painting Corp\n3614 Grande Reserve Way\nOrlando, FL 32837',
      href: 'https://www.google.com/maps/place/3614+Grande+Reserve+Way,+Orlando,+FL+32837/@28.4113995,-81.4236927,17z/data=!3m1!4b1!4m5!3m4!1s0x88e77dfb7649e0f7:0xbae1f31890debb77!8m2!3d28.4113948!4d-81.421504',
      icon: <HiLocationMarker style={ styles.footerIcon } />,
      title: strings?.bottom?.ourAddress,
    },
    {
      description: '(407) 493 - 3648',
      href: 'tel: (407) 493 - 3648',
      icon: <FaPhoneAlt style={ styles.footerIcon } />,
      title: strings?.bottom?.callUs,
    },
    {
      description: 'dcpaintingenterprisescorp@gmail.com',
      href: 'mailto: dcpaintingenterprisescorp@gmail.com',
      icon: <MdEmail style={ styles.footerIcon } />,
      title: strings?.bottom?.emailUs,
    },
    {
      description: strings?.bottom?.visitOurInstagramAccount,
      href: 'https://www.instagram.com/dcpaintingco/',
      icon: <AiFillInstagram style={ styles.footerIcon } />,
      title: strings?.bottom?.instagram,
    },
  ]

  useEffect(/* istanbul ignore next */ () => {
    getReviewsFromDB(setReviews, setShowToast, setToastMessage, setToastType)
    handleResize()
    window.addEventListener('resize', handleResize)

    if (!isDevBuild()) {
      trackVisit()
    }

    return /* istanbul ignore next */ () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(/* istanbul ignore next */ () => {
    sortReviews(reviews, reviewsSortBy, setReviews)

    return /* istanbul ignore next */ () => null
    /* eslint-disable react-hooks/exhaustive-deps*/
  }, [reviewsSortBy])

  const handleResize = () => {
    if (window.innerWidth > 800) {
      setShowFooterText(true)
    } else {
      setShowFooterText(false)
    }
  }

  const renderReviews = () => (
    <section className='reviews_section_container' style={ styles.section }>
        { renderTitle(strings?.customerExperience?.customerExperience) }
        <div className='reviews_section_shared_experiences_container' style={ styles.reviewsSharedExperiencesContainer }>
            <Text style={ styles.reviewsFormTitle } text={ strings?.customerExperience?.sharedExperiences } />
            <div className='reviews_section_sorting_tags_container' style={ styles.horizontalLayout }>
                <Text style={ styles.reviewsSortingTagLabel } text={ strings.customerExperience.sortBy } />
                <button
                    className='reviews_section_sorting_tags'
                    onClick={ /* istabul ignore next */ () => setReviewsSortBy(strings.customerExperience.newest) }
                    style={ { ...styles.reviewsSortingTag, ...{ backgroundColor: reviewsSortBy === strings.customerExperience.newest ? Colors.black : null } } }
                >
                    <Text
                        style={ { ...styles.reviewsSortingTagText, ...{ color: reviewsSortBy === strings.customerExperience.newest ? Colors.white : Colors.black } } }
                        text={ strings.customerExperience.newest }
                    />
                </button>
            </div>
            <div className='reviwes_section_shared_experiences_cards_container' style={ styles.reviewsCardContainer }>
              {
                reviews?.map(reviews => reviews?.approved && (
                  <Card style={ styles.reviewsCard }>
                    <Text style={ styles.reviewsCardTitle } text={ reviews?.name } />
                    <Text style={ styles.reviewsCardHighlight } text={ reviews?.highlight } />
                    <Text style={ styles.reviewsCardDetails } text={ reviews?.details } />
                  </Card>
                ))
              }
            </div>
        </div>
        <div className='reviews_section_share_experience_form_container' style={ styles.formContainer }>
            <Text style={ styles.reviewsFormTitle } text={ strings?.customerExperience?.sharedYourExperience } />
            <Card style={ styles.reviewsForm }>
                <div className='reviews_section_share_experience_top' style={ styles.reviewsFormTopContainer }>
                  <div className='reviews_sections_share_experience_top_user_info' style={ styles.reviewsFormUserInfo }>
                    <Input
                        error={ isFormNameEmpty }
                        label={ strings?.customerExperience?.name }
                        onChange={ setReviewsName }
                        placeholder={ strings?.customerExperience?.enterYourName }
                        style={ styles.reviewsFormUserInfoInput }
                        value={ reviewsName }
                    />
                    <Input
                        error={ false }
                        label={ strings?.customerExperience?.email }
                        onChange={ setFormEmail }
                        placeholder={ `(${ strings?.customerExperience?.optional })` }
                        style={ styles.reviewsFormUserInfoInput }
                        value={ formEmail }
                    />
                  </div>
                  <Input
                      error={ isFormHighlightEmpty }
                      label={ strings?.customerExperience?.title }
                      onChange={ setReviewsHighlight }
                      placeholder={ strings?.customerExperience?.whatDidYouLikeTheMost }
                      style={ styles.reviewsFormUserTitle }
                      value={ reviewsHighlight }
                  />
                </div>
                <div className='reviews_section_share_expecience_details' style={ styles.formDetailsContainer }>
                    <Input
                        error={ isFormDetailsEmpty }
                        label={ strings?.customerExperience?.details }
                        multiline
                        onChange={ setReviewsDetails }
                        placeholder={ strings?.customerExperience?.howWasYourExperience }
                        value={ reviewsDetails }
                    />
                </div>
                { isFormIncomplete && <Text style={ styles.reviewsFormError } text={ strings?.customerExperience?.allRequiredFieldsMustBeFilledOut } /> }
                <Button
                    onClick={ /* istanbul ignore next */ () => onSubmitReview(
                        reviewsDetails,
                        formEmail,
                        reviewsHighlight,
                        reviewsName,
                        setFormEmail,
                        setIsFormDetailsEmpty,
                        setIsFormHighlightEmpty,
                        setIsFormIncomplete,
                        setIsFormNameEmpty,
                        setReviewsDetails,
                        setReviewsHighlight,
                        setReviewsName,
                        setShowToast,
                        setToastMessage,
                        setToastType,
                    )
                    }
                    style={ styles.reviewsFormButton }
                    text={ strings?.customerExperience?.share }
                />
            </Card>
        </div>
    </section>
  )

  return (
    <div className='App' style={ styles.app }>
      <Header />
      <div className='body' style={ styles.body }>
        <Intro />
        <Services />
        { renderReviews() }
      </div>
      { renderFooter(footerSections, showFooterText) }
      { <Toast description={ toastMessage } type={ toastType } visible={ showToast }  /> }
    </div>
  )
}

export default App
