import Colors from '../../Platform/Colors'

const styles = {
    container: {
        backgroundColor: Colors.black,
        borderColor: 'transparent',
        borderRadius: 8,
        borderWidth: 0,
        cursor: 'pointer',
        height: 40,
        outline: 'none',
        width: 100,
    },
    text: {
        color: Colors.white,
        fontSize: 16,
        fontWeight: 'bold',
        margin: 0,
        padding: 0,
    },
}

export default styles
