import React from 'react'
import './Button.css'
import styles from './Button.styles'
import Text from '../Text/Text'

const Button = ({
    onClick = () => {},     // FUNC - To be called on component click.
    style = {},             // OBJECT - Custom style.
    text= '',               // STRING - Text to display.
}) => (
    <button className='component_button' onClick={ onClick } style={ { ...styles.container, ...style } }>
        <Text style={ styles.text } text={ text } />
    </button>
)

export default Button
