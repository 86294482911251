const styles = {
    container: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
    },
    item: {
        alignItems: 'center',
        borderRadius: 4,
        borderStyle: 'solid',
        borderWidth: 0,
        boxShadow: '04.0px 8.0px 8.0px hsl(0deg 0% 0% / 0.38)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        minHeight: 665,
        width: 350,
    },
    sectors: {
        alignItems: 'center',
        display: 'grid',
        gap: 50,
        gridTemplateColumns: 'repeat(auto-fit, 350px)',
        justifyContent: 'space-around',
        maxWidth: 1600,
        width: '100%',
    },
    text: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        justifyContent: 'flex-start',
        padding: 8,
    },
    title: {
        fontSize: 24,
        fontWeight: '700',
        marginBottom: 8,
        textAlign: 'center',
    },
}

export default styles
