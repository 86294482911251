import Colors from '../../Platform/Colors';

const styles = {
    container: {
        alignItems: 'center',
        background: Colors.black,
        display: 'flex',
        flexDirection: 'row',
        height: '20vh',
        justifyContent: 'space-between',
        overflow: 'hidden',
        paddingLeft: '15%',
        paddingRight: '15%',
    },
    headerRightContainer: {
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: '100%',
        width: '50%'
    },
};

export default styles;
