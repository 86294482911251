import Colors from '../../Platform/Colors';

const styles = {
    container: {
        alignItems: 'flex-start',
        borderRadius: 8,
        display: 'flex',
        height: 60,
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden',
        paddingLeft: 20,
        position: 'fixed',
        right: 50,
        top: 40,
        whiteSpace: 'nowrap',
        zIndex: 99,
    },
    description: {
        color: Colors.white,
        fontSize: 12,
    },
    title: {
        color: Colors.white,
        fontWeight: 'bold',
    },
};

export default styles;
