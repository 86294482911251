import React from 'react';
import './App.css';
import styles from './App.styles';
import { getReviews, submitReview } from './Services/Firebase/Reviews/Reviews';
import { strings } from './Services/Localization/Localization.service';

export const getReviewsFromDB = async (setReviews, setShowToast, setToastMessage, setToastType) => {
    const res = await getReviews();

    if (res?.success) {
        setReviews([...res?.data]);
    } else {
        setReviews([]);
        setToastType('error');
        setToastMessage(res?.error);
        setShowToast(true);
        setTimeout(/* istanbul ignore next */ () => setShowToast(false), 3000);
    };
};

export const onSubmitReview = async (
    details,
    email,
    highlight,
    name,
    setEmail,
    setIsFormDetailsEmpty,
    setIsFormHighlightEmpty,
    setIsFormIncomplete,
    setIsFormNameEmpty,
    setReviewsDetails,
    setReviewsHighlight,
    setReviewsName,
    setShowToast,
    setToastMessage,
    setToastType,
) => {
    if (details === '' || highlight === '' || name === '') {
        setIsFormIncomplete(true);

        if (details === '') {
            setIsFormDetailsEmpty(true);
        } else {
            setIsFormDetailsEmpty(false);
        };

        if (highlight === '') {
            setIsFormHighlightEmpty(true);
        } else {
            setIsFormHighlightEmpty(false);
        };

        if (name === '') {
            setIsFormNameEmpty(true);
        } else {
            setIsFormNameEmpty(false);
        };
    } else {
        setIsFormDetailsEmpty(false);
        setIsFormHighlightEmpty(false);
        setIsFormIncomplete(false);
        setIsFormNameEmpty(false);
        const res = await submitReview(details, email, highlight, name);

        if (res?.success) {
            setToastType('success');
            setToastMessage('Your experience was shared successfully');
            setReviewsDetails('');
            setEmail('');
            setReviewsHighlight('');
            setReviewsName('');
            setShowToast(true);
            setTimeout(/* istanbul ignore next */ () => setShowToast(false), 3000);
        } else {
            setToastType('error');
            setToastMessage(res?.error);
            setShowToast(true);
            setTimeout(/* istanbul ignore next */ () => setShowToast(false), 3000);
        }
    }
};

export const renderFooter = (footerSections, showFooterText) => (
  <div className='footer' style={ styles.footer }>
    {
      footerSections?.map(footerSection => (
        <a className='footerSection' href={ footerSection?.href } style={ styles.footerSection }>
          { footerSection?.icon }
          {
            showFooterText &&
            (
              <div className='footerSectionTextContainer' style={ styles.footerSectionTextContainer }>
                <div className='footerSectionTitle' style={ styles.footerSectionTitle }>{ footerSection?.title }</div>
                <div className='footerSectionDescription' style={ styles.footerSectionDescription }>{ footerSection?.description }</div>
              </div>
            )
          }
        </a>
      ))
    }
  </div>
);

export const sortReviews = (reviews, tag, setReviews) => {
    switch (tag) {
        case strings.customerExperience.newest:
            const sortedReviews = reviews.sort((a,b) => new Date(a.time).getTime() - new Date(b.time).getTime()).reverse();
            setReviews([...sortedReviews]);
            break;
        default:
            return;
    };
};