import React, { useEffect, useState } from 'react'
import styles from './Services.styles'
import ImageSlider from './Modules/ImageSlider/ImageSlider'
import { renderTitle } from '../../helpers.renders'
import Text from '../../Components/Text/Text'
import { getPictures } from '../../Services/Firebase/Storage/Storage'
import { strings } from '../../Services/Localization/Localization.service'

const Services = () => {
    const [commercialPictures, setCommercialPictures] = useState([])
    const [industrialPictures, setIndustrialPictures] = useState([])
    const [residentialPictures, setResidentialPictures] = useState([])
    const services = [
        {
            description: 'Interior and exterior painting services that can improve the appearance of your home quickly so you can get back to living in your home.',
            pictures: residentialPictures,
            title: 'Residential Services',
        },
        {
            description: 'Apply a fresh coat of paint to your office, buildings, apartments, manufacturing facilities, schools, and hospitals.',
            pictures: commercialPictures,
            title: 'Commercial Services',
        },
        {
            description: 'Protective layers of paint that will withstand the hard-working conditions it will be exposed to. Your surfaces will be less prone to corrosion.',
            pictures: industrialPictures,
            title: 'Industrial Services',
        },
    ]

    useEffect(/* istanbul ignore next */ () => {
        getPictures(setCommercialPictures, 'commercial')
        getPictures(setIndustrialPictures, 'industrial')
        getPictures(setResidentialPictures, 'residential')
    }, [])

    return (
        <section className='module_services' style={ styles.container }>
          { renderTitle(strings?.services?.title) }
          <div className='module_services_sectors' style={ styles.sectors }>
            {
              services?.map((service, index) => (
                <div className='module_services_sectors_item' key={ `${ index }_sector` } style={ styles.item }>
                  <ImageSlider images={ service?.pictures } />
                  <div className='module_services' style={ styles.text }>
                    <Text style={ styles.title } text={ service?.title } />
                    <Text text={ service?.description } />
                  </div>
                </div>
              ))
            }
          </div>
        </section>
    )
}

export default Services
