import { addDoc, collection, getDocs, getFirestore } from 'firebase/firestore/lite'
import moment from 'moment'
import firebase from '../firebase'

const collectionName = 'reviews'
const db = getFirestore(firebase)

export async function getReviews() {
    try {
        const collectionRef = collection(db, collectionName)
        const docsRef = await getDocs(collectionRef)
        return { data: docsRef.docs.map(doc => doc?.data()), error: '', success: true }
    } catch (error) {
        return { data: null, error: error?.message, success: false }
    }
}

export async function submitReview(details, email, highlight, name) {
    try {
        const collectionRef = collection(db, collectionName)
        await addDoc(collectionRef, {
            approved: false,
            details,
            email,
            highlight,
            name,
            time: moment().format(),
        })
        return { data: null, error: '', success: true }
    } catch (error) {
        return { data: null, error: error?.message, success: false }
    }
}