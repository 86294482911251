import React from 'react'
import { FaCalculator, FaClock, FaHandshake } from 'react-icons/fa'
import { IoRibbon } from 'react-icons/io5'
import { GiSparkles } from 'react-icons/gi'
import './Intro.css'
import styles from './Intro.styles'
import { renderTitle } from '../../helpers.renders'
import LayeredCircle from './Modules/LayeredCircle/LayeredCircle'
import Text from '../../Components/Text/Text'
import Colors from '../../Platform/Colors'
import { strings } from '../../Services/Localization/Localization.service'

const Intro = () => {
    const sections = [
        {
            color1: Colors.blue_chalk,
            color2: Colors.mauve,
            color3: Colors.mauve100,
            icon: <FaHandshake style={ styles.circleIcon } />,
            text: strings?.promise?.professionalExpertise,
        },
        {
            color1: Colors.black_squeeze,
            color2: Colors.botticelli,
            color3: Colors.sinbad,
            icon: <IoRibbon style={ styles.circleIcon } />,
            text: strings?.promise?.superiorQuality,
        },
        {
            color1: Colors.polar,
            color2: Colors.cruise,
            color3: Colors.sinbad100,
            icon: <GiSparkles style={ styles.circleIcon } />,
            text: strings?.promise?.cleanWorkAreas,
        },
        {
            color1: Colors.champagne,
            color2: Colors.macaronie_and_cheese,
            color3: Colors.california,
            icon: <FaClock style={ styles.circleIcon } />,
            text: strings?.promise?.onTime,
        },
        {
            color1: Colors.watusi,
            color2: Colors.rose_bud,
            color3: Colors.vivid_tangerine,
            icon: <FaCalculator style={ styles.circleIcon } />,
            text: strings?.promise?.onBudget,
        },
    ]

    return (
        <section className='module_intro' style={ styles.container }>
            { renderTitle(strings?.promise?.title) }
            <Text style={ styles.bodySubTitle } text={ strings?.promise?.subTitle } />
            <div className='module_intro_qualities' style={ styles.sectionContainer }>
            {
                sections?.map(section => (
                    <LayeredCircle
                        color1={ section.color1 }
                        color2={ section.color2 }
                        color3={ section.color3 }
                        icon={ section.icon }
                        text={ section.text }
                    /> 
                ))
            }
            </div>
            <p className='body_intro_footer' style={ styles.sectionsFooter }>- Free Estimates, call now (407) 493 - 3648 -</p>
        </section>  
    )
}

export default Intro
