import React from 'react'
import styles from './Text.styles'

const Text = ({
    text = '',      // STRING - Text.
    style = {},     // OBJECT - Custom style for text.
}) => (
    <p className='component_text' style={ { ...styles.container, ...style } }>{ text }</p>
)

export default Text
