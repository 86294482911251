import { getDownloadURL, getStorage, ref, list } from 'firebase/storage'

const storage = getStorage()

export async function getPictures(setImages, type) {
    try {
        const images = []
        const listRef = ref(storage, type)
        const picList = await list(listRef)

        for (let index = 0; index < picList.items.length; index++) {
            const img = await getDownloadURL(picList.items[index])
            images.push(img)
        }

        setImages(images)
    } catch (error) {
        console.log('ERROR: ', error)
    }
}
