import { doc, getDoc, getFirestore, increment, setDoc, updateDoc } from 'firebase/firestore/lite'
import moment from 'moment'
import firebase from '../firebase'

const collectionName = 'monthly_visits'
const db = getFirestore(firebase)

export async function trackVisit() {
    const documentName = moment().format('YYYY')
    const documentRef = doc(db, `${ collectionName }/${ documentName }`)
    const document = await getDoc(documentRef)
    const month = moment().format('MMMM')

    if (!!document.exists()) {
        await updateDoc(documentRef, { [month]: increment(1) })
    } else {
        setDoc(documentRef, { [month]: 1 })
    }
}

