const Colors = {
    black: 'black',
    black_squeeze: '#EBF6F8',
    blue_chalk: '#ECD4FF',
    botticelli: '#D9E6EC',
    california: '#FD9804',
    champagne: '#FBEDCE',
    cruise: '#BEEDE5',
    dove_gray: '#666666',
    electric_violet: '#4D22F9',
    gray400: '#444444',
    fog: '#DDD4FE',
    macaronie_and_cheese: '#FDCC7B',
    mauve: '#D5AAFF',
    mauve100: '#C5A3FF',
    polar: '#E0F8F5',
    red: 'red',
    rose_bud: '#FCB9AA',
    sinbad: '#A4C8D5',
    sinbad100: '#A7D9C9',
    snuff: '#DEE0ED',
    watusi: '#FFD8CC',
    white: 'white',
    vivid_tangerine: '#FF968A',
};

export default Colors;
