import Colors from './Platform/Colors';

const maxWidth = 1800;

const styles = {
    app: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 140,
        width: '100%',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    bodySubTitle: {
        fontSize: 20,
        fontWeight: 'semi',
        marginTop: 5,
        textAlign: 'center',
    },
    bodyTitle: {
        fontSize: 46,
        fontWeight: 'bold',
        marginBottom: 32,
        marginTop: 50,
        textAlign: 'center',
    },
    circleIcon: {
        color: Colors.white,
        height: 50,
        width: 50,
    },
    company_name: {
        color: Colors.white,
        fontSize: 26,
        fontWeight: 'bold',
        marginTop: 10,
    },
    footer: {
        background: Colors.black,
        bottom: 0,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        padding: 30,
        position: 'fixed',
        width: '100%',
    },
    footerIcon: {
        color: Colors.white,
        fontSize: 18,
        marginTop: 2,
    },
    footerSection: {
        display: 'flex',
        flexDirection: 'row',
        textDecoration: 'none',
        maxWidth: '25%',
    },
    footerSectionDescription: {
        color: Colors.white,
        fontSize: 14,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre',
    },
    footerSectionTextContainer: {
        marginLeft: 5,
        overflow: 'hidden',
    },
    footerSectionTitle: {
        color: Colors.white,
        fontSize: 18,
        fontWeight: 'semi',
        marginBottom: 5,
    },
    formContainer: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        justifyContent: 'flex-start',
        maxWidth,
        width: '100%',
    },
    formDetailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    horizontalLayout: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        justifyContent: 'flex-start',
    },
    referenceIcon: {
        color: Colors.white,
        height: 100,
        width: 100,
    },
    referenceIconContainer: {
        alignItems: 'center',
        background: 'transparent',
        borderWidth: 0,
        display: 'flex',
        flexDirection: 'column',
        textDecoration: 'none',
        width: '25%',
    },
    referenceIconText: {
        color: Colors.white,
        fontSize: 16,
        textAlign: 'center',
    },
    reviewsBodyContainer: {
        alignItems: 'center',
        background: Colors.dove_gray,
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 40,
        padding: 40,
    },
    reviewsBodyText: {
        color: Colors.white,
        fontSize: 18,
        width: '75%',
    },
    reviewsCard: {
        backgroundColor: Colors.black,
        maxHeight: 250,
        minWidth: 250,
        overflowY: 'auto',
        padding: 25,
    },
    reviewsCardContainer: {
        alingItems: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        justifyContent: 'flex-start',
        overflow: 'auto',
        width: '100%',
    },
    reviewsCardDetails: {
        color: Colors.white,
    },
    reviewsCardHighlight: {
        color: Colors.white,
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
        marginTop: 20,
    },
    reviewsCardTime: {
        color: Colors.white,
        fontSize: 12,
        fontWeight: '200',
    },
    reviewsCardTitle: {
        color: Colors.white,
        fontSize: 22,
        fontWeight: 'bold',
    },
    reviewsForm: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        justifyContent: 'flex-start',
        margin: 0,
        maxWidth,
        width: '100%',
    },
    reviewsFormButton: {
        alignSelf: 'flex-end',
        marginBottom: 10,
    },
    reviewsFormError: {
        color: Colors.red,
        fontSize: 14,
        marginBottom: 5,
        marginTop: 15,
    },
    reviewsFormLabel: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5,
        marginLeft: 10,
        marginTop: 10,
    },
    reviewsFormLabelContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    reviewsFormLabelRequired: {
        color: Colors.red,
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5,
        marginLeft: 5,
        marginTop: 10,
    },
    reviewsFormTitle: {
        fontSize: 22,
        fontWeight: 'bold',
        textAlign: 'left',
        marginBottom: 0,
    },
    reviewsFormTopContainer: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        minWidth: 150,
        width: '50%',
    },
    reviewsFormUserInfo: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        gap: 20,
        justifyContent: 'flex-start',
        width: '100%',
    },
    reviewsFormUserInfoInput: {
        maxWidth: 250,
        width: '100%',
    },
    reviewsFormUserTitle: {
        maxWidth: 500,
        width: '100%',
    },
    reviewsSharedExperiencesContainer: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        justifyContent: 'flex-start',
        maxWidth: 1800,
        width: '100%',
    },
    reviewsSortingTag: {
        alignItems: 'center',
        borderRadius: 40,
        borderStyle: 'solid',
        borderWidth: 1,
        cursor: 'pointer',
        display: 'flex',
        height: 40,
        justifyContent: 'center',
        outline: 'none',
        width: 100,
    },
    reviewsSortingTagLabel: {
        fontWeight: '500',
    },
    reviewsSortingTagText: {
        fontSize: 18,
        fontWeight: '600',
        textAlign: 'center',
    },
    section: {
        alignItems: 'center',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        gap: 30,
        justifyContent: 'center',
        overflow: 'hidden',
        padding: 30,
        width: '100%',
    },
    sectionContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        gap: 25,
        justifyContent: 'space-around',
        marginTop: 35,
        maxWidth,
        width: '100%',
    },
    sectionsFooter: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        marginTop: 50,
        textAlign: 'center',
    },
};

export default styles;
