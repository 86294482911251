const styles = {
  arrow: {
    height: '100%',
    opacity: 0.5,
    width: '100%',
  },
  arrowContainer: {
    background: 'transparent',
    borderColor: 'trasparent',
    borderWidth: 0,
    cursor: 'pointer',
    height: 40,
    outline: 'none',
    position: 'absolute',
    top: '50%',
    width: 40,
  },
  button: {
    background: 'transparent',
    borderColor: 'transparent',
    borderWidth: 0,
    cursor: 'pointer',
    height: 20,
    outline: 'none',
    width: 20,
  },
  buttonIcon: {
    height: 14,
    width: 14,
  },
  buttons: {
    alignSelf: 'center',
    boxSizing: 'inherit',
    position: 'absolute',
    bottom: 5,
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContext: 'space-between',
    width: '100%',
  },
  image_slider_container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContext: 'center',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  img: {
    height: 450,
    width: '100%',
  },
  text: {
    flexGrow: 1,
    fontSize: 60,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
}

export default styles