const styles = {
    container: {
        borderRadius: 8,
        boxSizing: 'border-box',
        padding: 2,
        width: '100%',
    },
    content: {
        alignItems: 'center',
        borderStyle: 'solid',
        borderRadius: 8,
        borderWidth: 1,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        width: '100%',
    },
    input: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        height: '100%',
        margin: 5,
        outline: 'none',
        resize: 'none',
        width: '100%',
    },
    label: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5,
        marginLeft: 10,
        marginTop: 10,
    }
};

export default styles;
