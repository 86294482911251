import React, { useState } from 'react'
import styles from './Input.styles'
import Colors from '../../Platform/Colors'

const Input = ({
    error = false,          // BOOL - Determines if there is an error.
    label = '',             // STRING - Label.
    multiline = false,      // BOOL - Input has multiple lines.
    onChange = () => {},    // FUNC - Sets value.
    placeholder = '',       // STRING - Placeholder value to display.
    style = {},             // OBJECT - Custom style.
    value = '',             // STRING - Value to display.
}) => {
    const [isFocused, setIsFocused] = useState(false)
    const borderColor = isFocused
        ? Colors.electric_violet
        : error
            ? Colors.red
            : Colors.snuff
    const height = !!multiline ? 200 : 40
    const padding = !!multiline ? 10 : 0

    return (
        <div className='component_input' style={ style }>
            {
                !!label &&
                <label htmlFor={ label }>
                    <p style={ styles.label }>{ label }</p>
                </label>
            }
            <div
                className='component_input_content'
                style={ { ...styles.container, ...{ backgroundColor: isFocused ? Colors.fog : 'transparent' } } }
            >
                <div
                    className='component_input_content_field'
                    style={ { ...styles.content, ...{ backgroundColor: Colors.white, borderColor, height, padding } } }
                >
                    {
                        multiline
                        ? (
                            <textarea
                                id={ label }
                                onBlur={ /* istanbul ignore next */ () => setIsFocused(false) }
                                onChange={ /* istanbul ignore next */ e => onChange(e.target.value) }
                                onFocus={ /* istanbul ignore next */ () => setIsFocused(true) }
                                placeholder={ placeholder }
                                style={ styles.input }
                                value={ value }
                            />
                        ) : (
                            <input
                                id={ label }
                                onBlur={ /* istanbul ignore next */ () => setIsFocused(false) }
                                onChange={ /* istanbul ignore next */ e => onChange(e.target.value) }
                                onFocus={ /* istanbul ignore next */ () => setIsFocused(true) }
                                placeholder={ placeholder }
                                style={ styles.input }
                                type='text'
                                value={ value }
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Input
