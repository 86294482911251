const styles = {
    circle1: {
        alignItems: 'center',
        borderRadius: 100,
        display: 'flex',
        flexDirection: 'column',
        height: 150,
        justifyContent: 'center',
        width: 150,
    },
    circle2: {
        alignItems: 'center',
        borderRadius: 90,
        display: 'flex',
        flexDirection: 'column',
        height: 125,
        justifyContent: 'center',
        width: 125,
    },
    circle3: {
        alignItems: 'center',
        borderRadius: 80,
        display: 'flex',
        flexDirection: 'column',
        height: 100,
        justifyContent: 'center',
        width: 100,
    },
    circleText: {
        fontSize: 20,
        fontWeight: 'semi',
        marginTop: 10,
        whiteSpace: 'pre-wrap',
        textAlign: 'center',
    },
    container: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
}

export default styles
